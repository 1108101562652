<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="relatorios"
      :headers="headers"
      :slots="['item.Status']"
      :actions="actions"
    >
      <template
        v-if="
          $user.get().role === 'admin' ||
          $user.get().role === 'gestor' ||
          $user.get().role === 'consultoria'
        "
        v-slot:btnCadastro
      >
        <router-link :to="`/${$user.get().role}/relatorios/entrega/form`">
          <v-btn color="success"> Nova entrega </v-btn>
        </router-link>
      </template>
      <template v-slot:[`item.Status`]="{ item }">
        <v-btn
          v-if="item.Status === 'Ativo' || item.Status === 1"
          rounded
          small
          plain
          text
          class="teal lighten-1 white--text"
          >{{ item.Status }}</v-btn
        >
        <v-btn
          v-else
          text
          rounded
          small
          plain
          class="red lighten-1 white--text"
          >{{ item.Status }}</v-btn
        >
      </template>
    </crud-list>
  </v-card>
</template>

<script>
import DeliveryService from '../../../services/api/delivery'

export default {
  data() {
    return {
      actions: [
        {
          title: 'Editar entrega',
          color: 'yellow darken-3',
          click: item =>
            this.$router.push({
              path: `/${this.$user.get().role}/relatorios/entrega/form`,
              query: {
                id: item.CodEntrega,
              },
            }),
          icon: 'mdi-pencil',
        },
        {
          title: 'Inativar entrega',
          color: 'red darken-3',
          click: item => this.delete(item),
          icon: 'mdi-delete',
        },
      ],
      relatorios: [],
      loading: true,
      headers: [
        {
          text: '',
          sortable: false,
          width: '60px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          sortable: true,
          width: '150px',
          value: 'Status',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Número',
          align: 'left',
          sortable: true,
          value: 'Numero',
          width: '150px',
          filterable: true,
        },
        {
          text: 'Consultor',
          align: 'left',
          sortable: true,
          value: 'ConsultorTecnicoNome',
          width: '250px',
          filterable: true,
        },
        {
          text: 'Titulo',
          align: 'left',
          sortable: true,
          value: 'title',
          width: 'auto',
        },
        {
          text: 'Descrição',
          align: 'left',
          sortable: true,
          value: 'Descricao',
          width: 'auto',
        },
      ],
    }
  },
  created() {
    this.getEntregas()
  },
  methods: {
    getEntregas() {
      this.api.get.entregas().then(data => {
        data = data.map(item => {
          item.Status = this.typeState(item.Status)
          return item
        })

        this.relatorios = data
        this.loading = false
      })
    },

    async delete(item) {
      try {
        await DeliveryService.report.delete(item.CodEntrega)

        this.Toast().fire({
          icon: 'success',
          title: 'Entrega deletado com sucesso!',
        })
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao deletar entrega!',
        })
      } finally {
        this.getEntregas()
      }
    },

    typeState(status) {
      if (status) {
        return 'Fechado'
      } else {
        return 'Aberto'
      }
    },
  },
}
</script>
